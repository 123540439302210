#text-page{
  position: relative;
  width: 100%;
  padding-top: 20px;
  padding-bottom: 30px;
  color: #2a2b2d;
  font-size: 0.9rem;
  line-height: 1.35;
  text-align: justify;
  min-height: 75vh;

  h1{
    color: $orange2;
    font-size: 1.99rem;
    font-weight: 400;
    text-align: center;
  }

  h5{
    font-size: 1.10rem;
  }

  ul,ol{
    padding-left: 17px;
  }

  strong{
    font-weight: 600;
  }
}
