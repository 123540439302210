#about-us-page {

	.main-section {
		position: relative;
		color: #ffffff;
		background-color: #010101;

		.bg {
			position: absolute;
			width: 57%;
			height: 100%;
			background-position: right center;
			background-repeat: no-repeat;
			background-size: cover;
			background-image: url('../images/pages/about-us/about-us-bg.jpg');
			z-index: 1;
		}

		.container {
			position: relative;
			z-index: 10;

			>.row {
				align-items: center;
				min-height: 80vh;
			}

			.col-contant {
				padding-top: 20px;
				padding-bottom: 20px;
				text-align: justify;

				.title {
					margin-bottom: 5px;
					color: $orange;
					font-weight: 300;
				}

				p {
					font-size: 0.95rem;
					line-height: 1.4;
				}
			}
		}

		@media screen and (max-width: 991px) {
			&:before {
				content: " ";
				position: absolute;
				top: 0;
				left: 0;
				width: 100%;
				height: 100%;
				background-color: rgba(0,0,0,0.85);
				z-index: 3;
			}

			.bg {
				width: 86%;
			}

			.container {
				.col-contant {
					text-align: center;
				}
			}
		}
		@media screen and (max-width: 575px) {
			.container {
				.col-contant {
					.title {
						font-size: 2.2rem;
					}

					p {
						font-size: 0.90rem;
					}
				}
			}
		}
	}

}
