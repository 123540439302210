#footer {
  position: relative;
  padding: 28px 0 24px 0;
  color: #ffffff;
  border-top: 2px solid #5d2b05;
  background-color: $black;

  a {
    display: inline-block;
    color: #ffffff;
  }

  .link-map {
    color: $orange;
    font-size: 1.1rem;
    font-weight: 500;
  }

  .container {
    >.row {
      align-items: center;
    }
  }

  .col-logo {
    a {
      display: inline-block;

      img {
        max-height: 174px;
      }
    }
  }

  .col-content {
    .col-contact {
      margin-bottom: 12px;
      text-align: right;

      .txt-contact {
        font-weight: 700;

        .icon {
          position: relative;
          top: 3px;
          margin-right: 3px;
          font-size: 125%;
        }
      }

      .networks {
        position: relative;
        top: 3px;

        .btn-network {
          display: inline-block;
          padding: 3px 6px;
          margin: 0 10px;
          color: #ffffff;
          cursor: pointer;
          font-size: 1.4rem;
          line-height: 1;
          @include transition(150ms);

          &:hover {
            opacity: 0.85;
          }
        }
      }
    }

    .col-footer {
      margin-top: 5px;
      margin-bottom: 5px;
      letter-spacing: 1px;

      .title {
        margin-bottom: 6px;
        font-size: 1.05rem;
        font-weight: 800;
      }
    }
  }

  @media screen and (min-width: 1600px) {
    // .col-logo {
    //   flex: 0 0 33.333333%;
    //   max-width: 33.333333%;
    // }
    //
    // .col-content {
    //   flex: 0 0 66.666667%;
    //   max-width: 66.666667%;
    // }
  }
  @media screen and (max-width: 991px) {
    padding: 22px 0 15px 0;

    .col-logo {
      margin-bottom: 10px;
      text-align: center;
    }

    .col-content {
      .col-contact, .col-footer {
        text-align: center;
      }

      .col-footer {
        margin-top: 8px;
        margin-bottom: 8px;
      }
    }
  }
  @media screen and (max-width: 767px) {
    .col-logo {
      margin-bottom: 14px;
    }

    .col-content {
      .col-contact {
        margin-bottom: 12px;

        .networks {
          display: block;
          top: 0;
          margin-top: 10px;
        }
      }
    }
  }
  @media screen and (max-width: 575px) {
    .col-logo {
      a {
        img {
          max-height: 130px;
        }
      }
    }

    .col-content {
      .col-footer {
        .title {
          margin-bottom: 3px;
          font-size: 0.90rem;
          font-weight: 700;
        }

        p {
          font-size: 0.90rem;
        }
      }
    }
  }
}
