#contact-page{

  .main-section{
    position: relative;
    padding-top: 22px;
    padding-bottom: 22px;
    background-color: #000000;;
    background-image: url('../images/pages/contact/bg.jpg');

    .container {
      >.row {
        min-height: 74vh;
        align-items: center;
        justify-content: center;
      }
    }

    .box-form{
      position: relative;
      display: block;
      padding: 32px 50px;
      color: #ffffff;
      border-radius: 24px;
      border-left: 3px solid $orangeLight;
      border-right: 3px solid $orangeLight;
      background-color: rgba(0,0,0,0.85);

      .title{
        color: $orange;
        font-size: 2.3rem;
        font-weight: 300;
        text-align: center;
      }

      form{
        margin-top: 30px;
      }

      .btn-send{
        padding: 0.7rem 0.95rem;
        width: 290px;
        max-width: 100%;
        color: #ffffff !important;
        font-size: 1.0rem;
        font-weight: 500;
        text-align: center;
        border: 1px solid $orangeLight;
        border-radius: 35px;
        background-color: $orangeLight;

        &:hover{
          border-color: #d96121;
          background-color: #d96121;
        }
      }
    }

    @media screen and (max-width: 575px){
      .box-form{
        padding: 22px 24px;

        .btn-send{
          width: 100%;
        }
      }
    }
  }

  .map-section {
    border-top: 3px solid $orange;
    
    iframe {
      width: 100%;
      height: 380px;
    }
  }

}
