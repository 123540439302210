.col-event-sample-1 {
  margin-bottom: 30px;

  ._box_ {
    position: relative;
    display: block;
    width: 100%;
    border-radius: 20px;
    padding-bottom: 20px;

    .box-image {
      position: relative;
      display: block;
      margin-bottom: 22px;
      border: 3px solid #000;
      border-radius: 20px;
      overflow: hidden;
      background-color: #141414;
      @include transition(250ms);

      img {
        @include transition(600ms);
        width: 100%;
        @include cp-property(transform, scale(1.0));
      }

      .bubble {
        position: absolute;
        top: 0;
        left: 0;
        padding: 7px 18px 8px 14px;
        color: #ffffff;
        font-size: 0.90rem;
        font-weight: 500;
        border-radius: 0 0 20px 0;
        background-color: $orange;
        z-index: 15;

        &:after {
          position: relative;
          display: inline-block;
          margin-left: 8px;
          font-family: "Font Awesome 5 Pro";
          font-size: 1.0rem;
          font-weight: 300;
        }

        &.available{
          background: #62bc00;

          &:after {
            content: "\f00c";
          }
        }
        &.unavailable{
          background: #d0021b;

          &:after {
            content: "\f00d";
          }
        }
      }
    }

    .box-descr {
      display: flex;
      flex-wrap: wrap;
      padding-bottom: 8px;
      margin-left: -15px;
      margin-right: -15px;
      align-items: center;
      text-align: center;

      .col-place {
        flex: 0 0 53%;
        max-width: 53%;
        font-size: 1.05rem;
        line-height: 1.1;
        text-align: left;
      }

      .col-date {
        flex: 0 0 47%;
        max-width: 47%;
        padding-left: 0;
        font-weight: 500;
        line-height: 1.1;
        text-align: right;
      }

      .col-band {
        margin-top: 10px;
        font-size: 1.4rem;
        font-weight: 800;
        line-height: 1.1;
      }

      .col-price {
        margin-top: 5px;
        color: #f8c586;
        font-size: 1.03rem;
      }
    }
  }

  a._box_ {
    color: #ffffff;
    cursor: pointer;
    text-decoration: none;

    &:after {
      content: " ";
      position: absolute;
      bottom: -10px;
      left: 50%;
      margin-left: -80px;
      width: 160px;
      border-top: 2px solid $orange;
      opacity: 0;
      visibility: hidden;
      @include transition(250ms);
    }

    &:hover {
      .box-image {
        border-color: $orange;

        img {
          @include cp-property(transform, scale(1.02));
        }
      }

      &:after {
        bottom: 0;
        opacity: 1;
        visibility: visible;
      }
    }
  }

  @media screen and (max-width: 1499px) {
    ._box_ {
      .box-descr {
        .col-place {
          font-size: 1.0rem;
        }

        .col-date {
          font-size: 0.90rem;
        }

        .col-band {
          margin-top: 12px;
        }
      }
    }
  }
  @media screen and (max-width: 991px) {
    ._box_ {
      .box-descr {
        .col-place, .col-date {
          flex: 0 0 100%;
          max-width: 100%;
          text-align: center;
        }

        .col-date {
          margin-top: 5px;
        }

        .col-band {
          font-size: 1.35rem;
        }

        .col-price {
          font-size: 0.95rem;
        }
      }
    }
  }
  @media screen and (max-width: 767px) {
    margin-bottom: 18px;
  }
}
