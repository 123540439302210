.btn-s1 {
  display: block;
  padding: 13px 15px;
  color: #ffffff !important;
  cursor: pointer;
  font-size: 1.05rem;
  font-weight: 600;
  text-align: center;
  text-decoration: none !important;
  text-transform: uppercase;
  border-radius: 4px;
  border-color: $orange;
  background-color: $orange;

  &:hover, &:focus, &:active {
    border-color: $orange2 !important;
    background-color: $orange2 !important;
  }

  &.bg-green {
    border-color: #62bc00;
    background-color: #62bc00;

    &:hover, &:focus, &:active {
      border-color: #58a406 !important;
      background-color: #58a406 !important;
    }
  }
}
