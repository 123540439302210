#header{
	// == Payments ==
	.header-content{
		position: relative;
		width: 100%;
		border-bottom: 4px solid $orange;

		.box-buy{
	    color: #ffffff;
			font-weight: 300;
			background-color: #232323;

	    .container{
	      display: flex;
	      flex-wrap: wrap;
	      padding-top: 10px;
	      padding-bottom: 10px;
	      font-size: 0.90rem;

	      .col-ml, .col-ssl{
	        color: #ffffff;
	        text-align: center;
	      }
	      .col-ml{
	        h5{
	          font-size: 0.95rem;
	          font-weight: 600;
	        }
	      }
	      .col-ssl{
	        max-width: 170px;
	        font-size: 1rem;
	        font-weight: 300;

	        img{
	          margin-right: 7px;
	        }

	        span{
	          position: relative;
	          top: 2px;
	        }
	      }

	      .col-logos{
	        text-align: center;

	        span{
						position: relative;
						top: -5px;
	          margin-right: 13px;
						font-weight: 300;
						letter-spacing: 2px;
	        }
	        img{
	          margin: 0 6px;
	        }

					.icon{
						position: relative;
						margin: 0 4px;
						font-size: 27px;
					}

					.img-icon{
						position: relative;
						margin-top: -12px;
						max-height: 25px;
					}

	        @media screen and (min-width: 992px) and (max-width: 1199px){
	          span{
	            font-size: 1.0rem;
	          }
	          img{
	            max-height: 19px;
	          }
	        }
	      }

				@media screen and (min-width: 992px) and (max-width: 1199px){
					padding-top: 8px;
					padding-bottom: 7px;

	        .col-ml{
	          h5{
							font-size: 0.85rem;
						}
	        }
					.col-ssl{
						max-width: 140px;

						img{
							width: 30px;
						}
						span{
							font-size: 0.85rem;
						}
					}
					.col-logos{
						span{
							font-size: 0.90rem;
						}

						.icon{
							margin: 0 2px;
							font-size: 23px;
						}
						.img-icon{
							max-height: 24px;
						}
					}
	      }
	      @media screen and (max-width: 991px){
					padding-top: 8px;
					padding-bottom: 7px;

	        .col-ml, .col-ssl{
	          display: none;
	        }

					.col-logos{
						text-align: center;
					}
	      }
	      @media screen and (max-width: 767px){
	        .col-logos{
	          span{
	            // display: block;
	            text-align: center;
	            font-size: 12px;
	            font-weight: 400;
	            margin-right: 5px;
	            text-transform: uppercase;
	          }

						.icon {
							font-size: 24px;
						}

	          img{
	            margin: 0 2px;
	            max-width: 75px;
	            max-height: 16px;
	          }
	        }
	      }
	    }
	    @media screen and (max-width: 575px){
	      display: none;
	    }
	  }
	}
	// == ==

	// == Navbar ==
	.navbar {
		color: #ffffff;
		background-color: #000000;

		.navbar-brand {
			img {
				height: 108px;
			}
		}

		.navbar-toggler {
			color: #ffffff;
			font-size: 2.0rem;
			border: 0;
		}

		#nav-collapse {
			.navbar-nav {
				.nav-item {
					.nav-link {
						color: #ffffff;
						cursor: pointer;
						font-size: 1.05rem;
						font-weight: 500;
					}
				}

				.video-item {
					.nav-link {
						@include flex-center-xy();
						// padding: 0.65rem 1.3rem 0.60rem 1.3rem;
						padding: 0 0 0 2px;
						width: 44px;
						height: 44px;
						color: #dc7d34;
						cursor: pointer;
						font-size: 1.05rem;
						text-shadow: 0px 0px 3px rgba(216, 117, 42, 0.8);
						border-radius: 26px;
						border: 2px solid #dc7d34;
						box-shadow: 0px 0px 3px rgba(216, 117, 42, 0.8);
						@include transition(250ms);

						&:hover {
							color: #f59348;
							text-shadow: 0px 0px 6px rgba(216, 117, 42, 0.9);
							box-shadow: 0px 0px 9px rgba(216, 117, 42, 0.8);
						}
					}
				}

				.orange-item {
					.nav-link {
						color: $orange;
					}
				}
				.orange-item-btn {
					.nav-link {
						padding: 0.55rem 1.60rem !important;
						color: #ffffff;
						border-radius: 22px;
						background-color: $orange;
					}
				}

				.networks-item {
					.nav-link {
						padding: 0;
						margin: 0 auto;
						display: flex;
						flex-wrap: wrap;
						align-items: center;
						justify-content: center;

						.btn-network {
							@include flex-center-xy();
							margin: 0 5px;
							width: 42px;
							height: 42px;
							color: #ffffff;
							font-size: 1.10rem;
							line-height: 1;
							text-shadow: 0 0 3px rgba(255,255,255,0.7);
							text-decoration: none !important;
							box-shadow: 0 0 3px rgba(255,255,255,0.8);
							border: 1px solid #ffffff;
							border-radius: 50%;

							&:first-child {
								margin-left: 0;
							}
							&:last-child {
								margin-right: 0;
							}

							&:hover {
								box-shadow: 0 0 6px rgba(255, 255, 255, 0.8);
								text-shadow: 0 0 7px rgba(255, 255, 255, 0.7);
							}

							.fa-facebook-f {
								font-size: 1.2rem;
							}

							.fa-instagram {
								font-size: 1.3rem;
							}
						}
					}
				}

				.dropdown {
					.dropdown-menu {
						padding: 0.55rem 0;
						border-radius: 0;
						background-color: $orange;

						li {
							.dropdown-item {
								padding: 0.3rem 1.0rem;
								color: #ffffff !important;
								font-size: 0.90rem;
								font-weight: 300;

								&:hover, &:focus, &:active {
									background-color: $black2;
								}
							}
						}
					}
				}

				.sep-item {
					.nav-link {
						position: relative;
						padding: 0;
						height: 100%;

						i {
							position: absolute;
							display: inline-block;
							height: 100%;
							border-left: 1px solid #ffffff;
						}
					}
				}
			}
		}

		@media screen and (min-width: 1200px) {
			#nav-collapse {
				.navbar-nav {
					.nav-item {
						margin-left: 1rem;
						margin-right: 1rem;
					}
				}
			}
		}
		@media screen and (min-width: 992px) and (max-width: 1199px) {
			.navbar-brand {
				img {
					height: 78px;
				}
			}

			#nav-collapse {
				.navbar-nav {
					.nav-item {
						margin-left: 0.4rem;
						margin-right: 0.4rem;
					}
				}
			}
		}
		@media screen and (max-width: 991px) {
			.navbar-brand {
				padding-top: 0.05rem;
				padding-bottom: 0.10rem;

				img {
					height: 54px;
				}
			}

			#nav-collapse {
				.navbar-nav {
					.nav-item {
						.nav-link {
							text-align: center;
							padding-top: 0.35rem;
							padding-bottom: 0.35rem;
							font-size: 1.0rem;
						}
					}

					.video-item {
						.nav-link {
							margin: 0 auto;
						}
					}

					.networks-item {
						order: 20;
						margin-top: 2px;
					}

					.sep-item {
						display: none !important;
					}
				}
			}
		}
	}
	// == ==

	// == Fixed buttons ==
	.fixed-networks-buttons {
		position: fixed;
		width: 60px;
		top: 50%;
		right: 0;
		margin-top: -160px;
		z-index: 80;

		.btn-network {
			@include flex-center-xy();
			width: 60px;
			height: 60px;
			color: #ffffff;
			cursor: pointer;
			font-size: 2.0rem;
			text-decoration: none !important;
			background-color: #999999;

			&:hover {
				opacity: 0.85;
			}

			&.ig {
				background: #f09433;
				background: -moz-linear-gradient(45deg, #f09433 0%, #e6683c 25%, #dc2743 50%, #cc2366 75%, #bc1888 100%);
				background: -webkit-linear-gradient(45deg, #f09433 0%,#e6683c 25%,#dc2743 50%,#cc2366 75%,#bc1888 100%);
				background: linear-gradient(45deg, #f09433 0%,#e6683c 25%,#dc2743 50%,#cc2366 75%,#bc1888 100%);
			}
			&.fb {
				background-color: #4267B2;
			}
			&.fb {
				background-color: #4267B2;
			}
			&.tt {
				background-color: #ffffff;

				i {
					margin-top: 2px;
					color: #111111;
					font-size: 94%;
					filter: drop-shadow(2px 0px 0px #FD3E3E) drop-shadow(-2px -2px 0px #4DE8F4);
				}
			}
			&.wh {
				background-color: #25d366;
			}
			&.yt {
				background-color: #ff0000;
			}

			&:first-child {
				border-radius: 12px 0 0 0;
			}
			&:last-child {
				border-radius: 0 0 0 12px;
			}
		}

		@media screen and (max-width: 575px) {
			width: 45px;

			.btn-network {
				width: 45px;
				height: 45px;
				font-size: 1.4rem;
			}
		}
	}
	// == ==

}
