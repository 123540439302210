.custom-group-s1{
  @include placeholder(#bebaba, 400, normal, 1.1rem);

  .form-control, .custom-select{
    padding: 0.375rem 1.2rem;
    height: 44px;
    color: #ffffff;
    font-size: 1.1rem;
    font-weight: 400;
    border-radius: 25px;
    border: 2px solid #dc7d34;
    box-shadow: 0px 0px 3px rgba(216, 117, 42, 0.8);
    background-color: transparent !important;
  }

  textarea{
    height: inherit !important;
    resize: none;
  }
}

.custom-group-s2 {
  legend {
    padding-bottom: 2px;
    font-weight: 600;
  }

  >div {
    .form-control, .custom-select {
      padding-left: 0;
      padding-right: 0;
      border: 0;
      border-bottom: 1px solid $orange;
      border-radius: 0;
    }

    .custom-select {
      option {
        &:first-child {
          color: #999999 !important;
        }
      }
    }
  }
}
