#place-page {

  .main-section {
    position: relative;
    padding: 35px 0;
    min-height: 77vh;
		color: #ffffff;
		background-color: #010101;

    .col-title {
      margin-bottom: 8px;
      text-align: center;

      h1 {
        color: $orange;
        font-weight: 300;
      }

      .box-tabs {
        position: relative;
        display: inline-block;
        margin-top: 12px;
        margin-bottom: 14px;
        width: 100%;
        text-align: center;

        .tabs {
          display: inline-flex;
          flex-wrap: wrap;
          border: 2px solid rgba(255,255,255,0.35);
          border-radius: 8px;

          .tab {
            display: inline-block;
            padding: 12px 15px;
            min-width: 200px;
            cursor: pointer;
            font-size: 1.05rem;
            font-weight: 500;
            letter-spacing: 1px;
            text-transform: uppercase;
            border-right: 2px solid rgba(255,255,255,0.35);

            &.active {
              background-color: rgba(255,255,255,0.20);
            }

            &:last-child {
              border-right: 0;
            }
          }
        }
      }
    }

    .col-image {
      margin-top: 15px;
      margin-bottom: 15px;

      .box-image {
        position: relative;
        width: 100%;
        height: 250px;
        border: 3px solid rgba(38, 37, 37, 0.3);
        border-radius: 8px;
        background-color: #151515;
        cursor: pointer;
        overflow: hidden;

        .img {
          width: 100%;
          height: 100%;
          border-radius: 6px;
          @include transition(600ms);
          @include cp-property(transform, scale(1.0));
        }

        &:hover {
          border-color: $orange;

          .img {
            @include cp-property(transform, scale(1.04) rotate(0.5deg));
          }
        }
      }
    }

    .col-info {
      .box-image {
        position: relative;
        float: right;
        display: inline-block;
        padding: 0;
        width: 100%;

        img {
          padding: 22px 22px 22px 22px;
          width: 100%;
          cursor: pointer;
          border-radius: 16px 16px 0 0;
          background-color: #ffffff;
        }
      }

      h5 {
        font-size: 1.30rem;
        font-weight: 700;
      }
      h6 {
        font-size: 1.05rem;
        font-weight: 600;
      }

      ul, ol {
        padding-left: 15px;
      }

      .box-table {
        display: inline-block;
        padding: 0 30px 14px 30px;
        width: 100%;
        border-radius: 0 0 16px 16px;
        background: #fff;

        .table {
          margin-bottom: 0;
          text-align: center;

          thead {
            tr {
              th:nth-child(3){
                width: 32%;
              }
            }
          }
        }
      }
    }

    @media screen and (max-width: 767px) {
      .col-info {
        .box-image {
          padding: 0;
        }

        .box-table {
          .table {
            thead {
              tr {
                th {
                  font-size: 0.85rem;
                  line-height: 1.1;
                  vertical-align: middle;

                  &:nth-child(3) {
                    font-size: 0.70rem;
                  }
                }
              }
            }

            tbody {
              tr {
                th, td {
                  line-height: 1.2;
                  font-size: 0.82rem;
                  vertical-align: middle;
                }
              }
            }
          }
        }
      }
    }
    @media screen and (max-width: 575px) {
      .col-title {
        .box-tabs {
          .tabs {
            width: 100%;

            .tab {
              padding: 12px 10px;
              min-width: 50%;
              font-size: 0.95rem;
            }
          }
        }
      }

      .col-image {
        margin-top: 7px;
        margin-bottom: 8px;
      }
    }
  }

}
