.modal-video {
  .modal-dialog {
    .modal-content {
      .modal-header {
        position: relative;
        padding: 0;
        height: auto;
        border: 0;
        z-index: 10;

        .close {
          position: absolute;
          padding: 0.0rem 1rem;
          margin: auto;
          top: 5px;
          right: 0;
          color: #ffffff;
          font-weight: 600;
          font-size: 3rem;
          line-height: 1;
          text-shadow: none;
          opacity: 1;
        }
      }

      .modal-body {
        padding: 0;
        line-height: 1.0;
        background: #000;

        video {
          line-height: 1.0;
          width: 100%;
          height: 450px;
          object-fit: cover;
          background-color: #000000;
        }
      }
    }
  }

  @media screen and (max-width: 991px) {
    .modal-dialog {
      .modal-content {
        .modal-body {
          video {
            height: 360px;
          }
        }
      }
    }
  }
}

.modal-event {
  .modal-dialog {
    .modal-content {
      box-shadow: 0 0 4px rgba(255,255,255,0.5);

      .modal-header {
        position: relative;
        padding: 0;
        height: auto;
        border: 0;
        z-index: 10;

        .close {
          position: absolute;
          padding: 0.0rem 1rem;
          margin: auto;
          top: 7px;
          left: 2px;
          color: #ffffff;
          font-weight: 600;
          font-size: 3rem;
          line-height: 1;
          text-shadow: 1px 1px 2px rgba(0,0,0,0.8);
          opacity: 1;
        }
      }

      .modal-body {
        padding: 0;
        background-color: #000000;

        .col-basic-info {
          flex: 0 0 495px;
          max-width: 495px;
          color: #ffffff;

          .box-image {
            img {
              width: 100%;
            }
          }

          .box-video {
            iframe {
              width: 100%;
              height: 350px;
              background-color: #000000;
            }
          }

          .box-text {
            padding: 18px 24px 22px 24px;
            line-height: 1.35;

            strong {
              font-weight: 600;
            }

            .title {
              margin-bottom: 5px;
              text-align: center;
              font-size: 1.20rem;
              font-weight: 700;
              line-height: 1.1;
            }

            .date {
              margin-bottom: 9px;
              color: #8a8a8c;
              font-weight: 600;
              text-align: center;
            }
          }

          .btn-s1 {
            margin-top: 20px;
          }
        }

        .col-table {
          padding: 10px 20px 14px 20px;
          background-color: #0d0d0d;

          .table-responsive {
            margin-bottom: 0;
            height: 100%;
          }

          .table {
            color: #ffffff;
            height: 100%;

            thead, tbody {
              tr {
                th, td {
                  vertical-align: middle;
                }
              }
            }
            thead {
              tr {
                th,td {
                  border-top: 0;
                  border-bottom-width: 1px;
                  font-size: 0.76rem;
                  line-height: 1.10;
                  text-align: center;
                  text-transform: uppercase;
                }
              }
            }

            tbody {
              tr {
                th,td {
                  padding: 0.4rem 0.65rem 0.35rem 0.65rem;
                  border-top: 0;
                  font-size: 0.85rem;
                  text-align: center;

                  &:first-child {
                    font-weight: 600;
                  }
                }

                &.website {
                  padding-top: 0.55rem;
                  font-weight: 600;
                  letter-spacing: 3px;

                  &:hover {
                    background-color: transparent !important;
                  }
                }

                &:hover {
                  background-color: #242424;
                }
              }
            }
          }
        }

        @media screen and (max-width: 1199px) {
          .col-basic-info {
            flex: 0 0 100%;
            max-width: 100%;
          }
        }
        @media screen and (max-width: 575px) {
          .col-table {
            .table-responsive {
              padding-bottom: 10px;
            }
          }
        }
      }
    }
  }
}

.modal-form {
  .modal-dialog {
    .modal-content {
      .modal-header {
        position: relative;
        padding: 7px 0;
        height: auto;
        border: 0;
        background-color: $orange;
        z-index: 10;

        .modal-title {
          padding-right: 45px;
          padding-left: 10px;
          width: 100%;
          color: #ffffff;
          font-size: 1.10rem;
          font-weight: 600;
          text-align: center;
          line-height: 1.1;
        }

        .close {
          position: absolute;
          padding: 0.0rem 1rem;
          margin: auto;
          top: 0;
          right: 0;
          color: #ffffff;
          font-weight: 400;
          font-size: 2.8rem;
          line-height: 1;
          text-shadow: none;
          opacity: 1;
        }
      }

      .modal-body {
        padding: 1.3rem 2rem 1.6rem 2rem;
      }
    }
  }
}
