#home-page {
	color: #ffffff;
	background-color: #000000;

	.banner-section {
		position: relative;
		width: 100%;

		.swiper {
			// &:before {
			// 	content: " ";
			// 	position: absolute;
			// 	bottom: 0;
			// 	left: 0;
			// 	width: 100%;
			// 	height: 70px;
			// 	background: rgb(0,0,0);
			// 	background: linear-gradient(0deg, rgba(0,0,0,1) 21%, rgba(0,0,0,0) 100%);
			// 	z-index: 40;
			// }

			.swiper-button-prev, .swiper-button-next {
		    width: 50px;
		    height: 50px;
		    // border: 2px solid transparent;
		    border-radius: 50%;
		    background-color: transparent;
		    @include transition(150ms);
		    opacity: 1;

		    &:after {
		      font-family: "Font Awesome 5 Pro";
		      font-weight: 400;
		      font-size: 2.5rem;
		    }
		  }

			.swiper-button-disabled {
				pointer-events: all !important;
			}

		  .swiper-button-prev {
		    left: 20px;
		    padding-right: 2px;

		    &:after {
		      content: "\f053";
		    }
		  }

		  .swiper-button-next {
		    right: 20px;
		    padding-left: 3px;

		    &:after {
		      content: "\f054";
		    }
		  }

			.swiper-pagination {
				bottom: 30px;
				z-index: 110;

				.swiper-pagination-bullet {
					width: 11px;
					height: 11px;
					border: 2px solid #dc7d34;
					box-shadow: 0px 0px 3px rgba(216, 117, 42, 0.8);
					background-color: transparent;
					opacity: 1;
					@include transition(100ms);
				}

				.swiper-pagination-bullet-active {
					width: 40px;
					border-radius: 5px;
				}
			}

		  @media screen and (max-width: 767px) {
		    .swiper-button-prev, .swiper-button-next {
		      width: 40px;
		      height: 40px;
		      border-width: 1px;

		      &:after {
		        font-size: 1.3rem;
						font-weight: 700;
		      }
		    }

		    .swiper-button-prev {
		      left: 0;
		    }
		    .swiper-button-next {
		      right: 0;
		    }
		  }

			.swiper-slide {
				background-color: #1e1d1d;

				.box {
					display: block;
					@include transition(250ms);

					img {
						width: 100%;
					}
				}

				a.box {
					cursor: pointer;
				}
			}
		}

		.desktop {}
		.mobile {
			display: none;
		}

		@media screen and (max-width: 991px) {
			.desktop {
				display: none;
			}
			.mobile {
				display: block;
			}
		}
	}

	.events-section {
		padding-top: 25px;
		padding-bottom: 25px;

		.col-top-title {
			margin-bottom: 20px;

			h2 {
				position: relative;
				padding: 5px 0 5px 15px;
				position: relative;
				color: #ffffff;
				font-size: 1.80rem;
				font-weight: 300;
				line-height: 1.0;
				border-left: 6px solid $orange;
			}
		}
	}

	.sponsors-section {
		padding-top: 22px;
		padding-bottom: 10px;
		background-color: #ffffff;

		.title {
			color: $orange;
			font-size: 1.8rem;
			text-align: center;
		}

		.swiper {
			.swiper-slide {
				.box-image {
					display: flex;
					flex: 0 0 100%;
					max-width: 100%;
					height: 230px;
					align-items: center;
					text-align: center;
					border-radius: 5px;
					background-color: #ffffff;

					.image {
						display: inline-block;
						width: 100%;

						img {
							max-width: 100%;
							max-height: 220px;
						}
					}
				}
			}

			.swiper-pagination {
				bottom: 4px;

				.swiper-pagination-bullet {
					background-color: rgba(0,0,0,0.7);
				}
				.swiper-pagination-bullet-active {
					background-color: #000;
				}

				@media screen and (min-width: 992px) {
					opacity: 0;
					visibility: hidden;
				}
			}
		}
	}

	.note-section {
		position: relative;
		padding: 15px 15px;
		text-align: center;
		color: #ffffff;
		background-color: $orange;

		h5 {
			font-size: 1.1rem;
		}

		@media screen and (max-width: 575px) {
			padding: 12px 15px 10px 15px;

			h5 {
				font-size: 0.95rem;
				line-height: 1.1;
			}
		}
	}

}
